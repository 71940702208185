<template>
    <div class="goods-info-conatiner">
        <div id="minirefresh" class="minirefresh-wrap"
             :style="{height:scrollHeight+'px',background:'#f6f6f6'}">
            <div class="minirefresh-scroll">
                <div v-if="swiperList.length > 0">
                    <swiper :list="swiperList"></swiper>
                </div>
                <div class="card">
                    <cardV1
                            :title="goodsInfo.title"
                            :deduct="goodsInfo.deduct"
                            :sales_number="basicInfo.sale_number"
                            :product_price="goodsInfo.product_price"
                            :market_price="goodsInfo.market_price"
                            :mail_type="goodsInfo.mail_type"
                            :return_exchange="goodsInfo.return_exchange"
                    ></cardV1>
                </div>
                <div class="goods-description">
                    <div class="description_title">商品详情</div>
                    <div class="description_content">
                        <div v-html="goodsInfo.content"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="page-bottom">
            <div class="button" @click="goToGetPage">领取</div>
        </div>
    </div>
</template>

<script>
  import { mapActions } from 'vuex'
  import swiper from '@T/community/swiper/swiperV1'
  import cardV1 from '@T/community/card/cardV1'
  import parseV1 from '@C/parse/parseV1'
  import footbarV1 from '@T/community/footbar/footbarV1.vue'
  import rulesV1 from '@T/community/popup/rulesV1'
  // import MiniRefresh from 'minirefresh';
  import 'minirefresh/dist/debug/minirefresh.css'

  export default {
    name: 'goods-info',
    components: {
      swiper,
      cardV1,
      parseV1,
      footbarV1,
      rulesV1,
    },
    data () {
      return {
        id: 0, //商品id
        card_id: 0,//优惠卡订单ID
        type: 0,//用户选择的领取方式，自提还是快递
        number: 1, //商品数量
        //展示商品规格选择
        rule: {
          show: false,
          type: 0, //0 加入购物车 1立即下单
        },
        goods_spec_checkeds: {}, //已经选择的规格
        goodsInfo: {
          cover: '',
          cover_images: [],
          video_path: '',
          title: '',
          deduct: 0,
          market_price: '',
          product_price: '',
          sales_number: 0,
          mail_type: null,
          favorite_id: 0,
          stock_number: 0,
          goods_products: {},
          goods_specs: [],
        },
        canContinue: false,
        basicInfo: {},
        scrollHeight: window.innerHeight - 60
      }
    },
    computed: {
      swiperList () {
        let list = [],
          videoList = [],
          imgList = []
        if (this.goodsInfo?.video_path != '') {
          videoList.push({
            type: 'video',
            url: this.goodsInfo.video_path,
          })
        }
        imgList = this.goodsInfo?.cover_images.map((val) => ({
          type: 'image',
          url: val,
        }))
        list = [...videoList, ...imgList]
        return list
      },
      isFollow () {
        if (this.goodsInfo.favorite_id > 0) {
          return true
        }
        return false
      },
    },
    mounted () {
      document.title = '商品详情'
      this.id = this.$route.query.goods_id || 0
      this.card_id = this.$route.query.card_id || 0
      this.type = this.$route.query.type || 0
      this.getGoodsInfo()
      this.canContinue = true
      this.refresh()
    },
    methods: {
      ...mapActions('community_order', ['reset']),
      refresh () {
        const that = this
        this.miniRefresh = new window.MiniRefresh({
          container: '#minirefresh',
          down: {
            offset: 50,// 触发下拉的阈值，当下拉距离大于这个阈值后，在松开时会触发下拉刷新
            callback: function () {
              that.getGoodsInfo()
              that.miniRefresh.endDownLoading()
            }
          },
          up: {
            isLock: true
          },
          isScrollBar: false
        })
      },
      async getGoodsInfo () {
        try {
          let query = await this.$api.couponCard.getGoodsInfo({
            goods_id: this.id,
            card_id: this.card_id,
          })
          this.goodsInfo = query.data.goodInfo
          this.basicInfo = query.data
          console.log('this.goodsInfo', this.goodsInfo)
        } catch (error) {
          //
        }
      },
      async follow () {
        try {
          let query = null
          if (this.goodsInfo.favorite_id) {
            query = await this.$api.community.goods.followDels({
              favorite_ids: [this.goodsInfo.favorite_id].join(','),
            })
            this.goodsInfo.favorite_id = 0
          } else {
            query = await this.$api.community.goods.followAdd({
              goods_id: this.id,
            })
            this.goodsInfo.favorite_id = Number(query.data)
          }
        } catch (error) {
          console.log('follow', error)
        }
      },
      goCar () {
        if (this.$platform.wxsdk.isWechat()) {
          this.$platform.wxsdk.wxRoute({
            type: 'navigateTo',
            url: '/web/community/cart',
          })
        } else {
          this.$router.push({path: 'cart'})
        }
      },
      async addCar () {
        this.rule = {
          show: true,
          type: 0,
        }
      },
      buy () {
        // console.log("buy");
        this.rule = {
          show: true,
          type: 1,
        }
      },
      onConfirm (param) {
        // console.log(param);
        this.reset()
        if (this.rule.type == 0) {
          this.onAddCar(param)
        } else {
          this.onBuy(param)
        }
      },
      onBuy (param) {
        let query = {
          ...param,
          specs: JSON.stringify(param.specs),
          num: param.num,
          goods_id: this.id,
        }
        if (this.canContinue && this.$platform.wxsdk.isWechat()) {
          this.canContinue = false
          query = encodeURIComponent(JSON.stringify(query))
          this.$platform.wxsdk.wxRoute({
            type: 'navigateTo',
            url: '/web/community/order-confrim?query=' + query,
          })
          setTimeout(() => {
            this.canContinue = true
          }, 500)
        } else {
          this.$router.push({
            path: 'order-confrim',
            query: query,
          })
        }
      },
      share () {
        let query = encodeURIComponent(JSON.stringify(this.goodsInfo))
        this.$platform.wxsdk.wxRoute({
          type: 'navigateTo',
          url: '/web/share/share?query=' + query,
        })
      },
      goToGetPage () {
        let params = {
          card_id: this.card_id,
          goods_id: this.id + '_' + this.type
        }
        let options = encodeURIComponent(JSON.stringify(params))
        if (this.canContinue) {
          this.canContinue = false
          this.$platform.wxsdk.wxRoute({
            type: 'navigateTo',
            url: '/web/couponCard/card-use-apply?query=' + options,
          })

          setTimeout(() => {
            this.canContinue = true
          }, 500)
        }

      }
    },
  }
</script>

<style scoped lang="scss">
    .goods-info-conatiner {
        width: 100vw;
        /*min-height: 100vh;*/
        background: #f6f6f6;
        /*overflow: auto;*/
        /*-webkit-overflow-scrolling: touch;*/

        .card {
            box-sizing: border-box;
            border-radius: 10px;
            padding: 10px 15px;
        }

        .goods-description {
            margin: 0 15px 10px 15px;
            background: #fff;

            .description_title {
                background: #fff;
                padding: 10px;
                font-size: 14px;
                color: #333333;
            }

            .description_content {
                background: #fff;
                padding: 10px;
                font-size: 14px;

                /deep/ img {
                    width: 100%;
                }

            }
        }

        .area {
            width: 100%;
            height: 57px;
        }

    }

    .page-bottom {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        height: 58px;
        background: #fff;
        border-top: #f6f6f6 solid 1px;
        padding: 0 15px;
        display: flex;
        align-items: center;

        .button {
            width: 100%;
            height: 44px;
            background: linear-gradient(139deg, #FAC484 0%, #F46A17 100%);
            -webkit-border-radius: 6px;
            -moz-border-radius: 6px;
            border-radius: 6px;
            color: #ffffff;
            font-size: 14px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .minirefresh-scroll {
        background: #f6f6f6
    }

    /deep/ .minirefresh-theme-default .minirefresh-upwrap {
        min-height: 0;
        padding: 0;
        text-align: center;
        height: 0;
    }
</style>